<div (clickOutside)="uncheck()" [formGroup]="hamburgerForm" id="menuToggle">
  <input formControlName="hamCheck" type="checkbox"/>
  <div class="ham-wrap">
    <span class="t"></span> <span class="m"></span> <span class="b"></span>
  </div>
  <ul id="menu">
    <li><a (click)="uncheck();" SimpleSmoothScroll [offset]="-300" [showHash]="false" href="#head">Головна</a></li>
    <li><a (click)="uncheck();" SimpleSmoothScroll [offset]="-150" [showHash]="false" href="#production">Продукцiя</a>
    </li>
    <li><a (click)="uncheck();" SimpleSmoothScroll [showHash]="false" href="#mission">Мiсiя</a></li>
    <li><a (click)="uncheck();" SimpleSmoothScroll [offset]="-300" [showHash]="false" href="#about-us">Про нас</a></li>
    <li><a (click)="uncheck();" SimpleSmoothScroll [showHash]="false" href="#contacts">Контакти</a></li>
  </ul>
</div>
