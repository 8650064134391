<div class="section-wrapper" id="callback">
    <h2>Зв'язатися з нами</h2>
</div>
<div class="section-form-wrapper">
    <form [formGroup]="feedbackForm" class="form-wrapper">

        <div class="form-group">
            <label for="name">Им'я *</label>
            <div class="form-field">
                <i class="icon icon-user2"></i>
                <input type="text" formControlName="name" id="name" class="form-control" required
                       [ngClass]="!feedbackForm.controls['name'].valid && feedbackForm.controls['name']?.touched  ? 'invalid' : '' ">
            </div>
        </div>
        <div class="form-group">
            <label for="email">Email *</label>
            <div class="form-field">
                <i class="icon icon-mail"></i>
                <input type="text" id="email" formControlName="email" class="form-control" required
                       [ngClass]="!feedbackForm.controls['email'].valid && feedbackForm.controls['email']?.touched  ? 'invalid' : '' ">
            </div>
        </div>
        <div class="form-group">
            <label for="phone">Телефон *</label>
            <div class="form-field">
                <i class="icon icon-phone"></i>
                <input type="text" id="phone" formControlName="phone" class="form-control" required
                       [ngClass]="!feedbackForm.controls['phone'].valid && feedbackForm.controls['phone']?.touched  ? 'invalid' : '' ">
            </div>
        </div>
        <div class="form-group form-textarea">
            <label for="comment">Коментар</label>
            <div class="form-field">
                <textarea name="" id="comment" cols="30" rows="10" formControlName="comment">  </textarea>
            </div>
        </div>
        <div class="form-group form-button">
            <div class="form-field">
                <button type="submit" class="form-submit" [disabled]="!feedbackForm.valid" (click)="onSubmit()">надіслати</button>
            </div>
        </div>


    </form>
    <!--</div>-->

</div>
