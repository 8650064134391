<section class="section-wrapper" id="mission">
  <blockquote class="testimonial">
    <q>
      Одна з найскладніших задач сучасності —<br />
      проблеми уповільнення процесу знищення живої природи
    </q>
    <footer>— Арчі Карр</footer>
  </blockquote>
  <h2 class="mission">Наша місія</h2>
</section>
<article class="mission-wrapper">
  <p class="mission">
    Ми щиро і свято віримо, що кожен із тих, хто живе на планеті Земля,
    здатний внести свій внесок у процес збереження нашої природи. Ми обрали
    для себе сферу, де бачимо в собі сили та можливість робити зміни.
    Зараз наша команда виступає за припинення виробництва і використання
    поліетиленових пакетів, які завдають непоправної шкоди природі і всім її
    мешканцям.
  </p>
  <p class="mission">
    Використовуючи сучасні технології та досвід розвинених країн, ми пропонуємо
    виробництво упаковки з біорозчинних компонентів. При переході на
    біорозчинну упаковку вже через декілька років ми зможемо жити в повністю
    очищеному від поліетиленового сміття світі.
  </p>
  <p class="mission">
    Наша компанія надає широкий вибір упаковки з поліетилену з
    застосуванням добавки d2w, яка по закінченню 3 років та через вплив природних
    факторів, повністю розкладає поліетилен до водню. Ми щиро віримо, що
    відповідальність перед своїми покупцями і перед нашою природою, її
    красою і багатствами, допоможе підприємцям України і решти світу
    зробити крок в сторону іншого, здорового і чистого світу для всіх нас!
  </p>
</article>
