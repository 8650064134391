<header>
  <nav class="sp-nav">
    <a SimpleSmoothScroll [offset]="-300" [showHash]="false" class="logo" href="#head">Smart Pockets</a>

    <div class="upper-row-wrapper">
      <div class="phone-wrapper">
        <a href="tel:380683392449">(068) 339 - 24 - 49</a><br/>
        <a href="tel:380997602480">(099) 760 - 24 - 80</a><br/>
      </div>
      <ul class="networks-wrapper">
        <li>
          <a
            class="tlgrm"
            href="tg://resolve?number=380683392449"
          >Telegram</a>
        </li>
        <li>
          <a class="ig" href="https://www.instagram.com/smartpockets/">IG</a>
        </li>
        <li>
          <a
            class="whatsup"
            href="whatsapp://send?phone=+380683392449"
          >What's Up</a>
        </li>
        <li>
          <a class="tt" href="https://www.tiktok.com/@smartpockets">TT</a>
        </li>
        <li>
          <a
            class="viber"
            href="viber://add?number=380683392449"
          >Viber</a>
        </li>
        <li>
          <a class="fb" href="https://www.facebook.com/smartpockets.ua/">FB</a>
        </li>

      </ul>
    </div>
    <div class="lower-row-wrapper">
      <ul class="">
        <li><a SimpleSmoothScroll [offset]="-300" [showHash]="false" href="#head">Головна</a></li>
        <li><a SimpleSmoothScroll [offset]="-300" [showHash]="false" href="#production">Продукцiя</a></li>
        <li><a SimpleSmoothScroll [showHash]="false" href="#mission">Місія</a></li>
        <li><a SimpleSmoothScroll [offset]="-300" [showHash]="false" href="#about-us">Про нас</a></li>
        <li><a SimpleSmoothScroll [showHash]="false" href="#contacts">Контакти</a></li>
      </ul>
    </div>
    <nav class="header-ham">
      <app-hamburger></app-hamburger>

    </nav>

  </nav>
</header>
