<footer id="contacts">
  <div class="section-wrapper">
    <div class="footer-left">
      <div class="vcard">
        <h4 class="fn org">Smart Pockets</h4>

        <address class="adr">
          <span class="locality">м. Одеса</span>
          <div class="street-address">вул. Середньофонтанська 19а, 2 поверх</div>
        </address>
      </div>
      <ul class="networks-wrapper">
        <li>
          <a class="tlgrm" href="tg://resolve??domain=Smartpockets">Telegram
          </a>
        </li>
        <li>
          <a class="ig" href="https://www.instagram.com/smartpockets/">IG</a>
        </li>
        <li>
          <a href="viber://add?number=380683392449"
             class="viber"
          >Viber
          </a>
        </li>
        <li>
          <a class="tt" href="https://www.tiktok.com/@smartpockets">TT</a>
        </li>
        <li>
          <a class="whatsup"
              href="whatsapp://send?phone=+380683392449"
          >What's Up
          </a>
        </li>
        <li>
          <a class="fb" href="https://www.facebook.com/smartpockets.ua/">FB</a>
        </li>
      </ul>
    </div>

    <div class="footer-center"></div>
    <div class="footer-right">
      <div class="vcard">
        <a class="tel" href="tel:380683392449"
        >(068) 339 &ndash; 24 &ndash; 49</a
        ><br/>
        <a class="tel" href="tel:380997602480"
        >(099)760 &ndash; 24 &ndash; 80</a
        ><br/>
        <a class="email" href="mailto:smartpockets.chief@gmail.com">smartpockets.chief@gmail.com
        </a>
      </div>
    </div>
  </div>
</footer>
