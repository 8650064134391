import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-our-mission',
  templateUrl: './our-mission.component.html',
  styleUrls: ['./our-mission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OurMissionComponent {

}
