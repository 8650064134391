<section class="section-wrapper" id="about-us">
  <h2>Про нас</h2>
  <div class="about-text-wrapper">
    <p class="about-text">
      &nbsp;&nbsp;&nbsp;Компанія Smart Pockets надає широку лінійку
      продукції з поліетилену з використанням біорозчинних добавок. Випускаємо такий асортимент продукції:
      популярні у використанні пакети банан, майка, з петлевою ручкою, паперові, крафт, та кур'єрські.
      Найбільшою нашою гордістю є можливість виготовляти крохмальні пакети “PLA”.<br />
      &nbsp;&nbsp;&nbsp;Надаємо нашим клієнтам стильну і яскраву
      упаковку для їх товарів. Саме тому наші пакети ідеально підходять для
      магазинів одягу, взуття, аксесуарів, парфумерії, дрібної і крупної побутової
      техніки і багато чого іншого.<br />
      &nbsp;&nbsp;&nbsp;Ми завжди йдемо в ногу з часом і готові розробити
      для вас індивідуальну упаковку, яка буде максимально відображати ваші
      основні переваги на ринку.
    </p>
  </div>
</section>
