<div id="head">
    <ngx-slick-carousel
            [config]="slideConfig"
            class="carousel"
    >
        <div *ngFor="let slide of slides;trackBy: trackByFn" class="partner-item" ngxSlickItem>
            <img [alt]="slide.alt" [attr.data-lazy]="slide.img" width="100%"/>
        </div>
    </ngx-slick-carousel>
    <div class="lower-row">
        <div class="lower-row-item"><a SimpleSmoothScroll [offset]="-300" [showHash]="false" href="#mission">Наша
            мiciя</a>
        </div>
        <div class="lower-row-item"><a SimpleSmoothScroll [offset]="-300" [showHash]="false" href="#about-us">Про нас</a>
        </div>
        <div class="lower-row-item"><a SimpleSmoothScroll [offset]="-300" [showHash]="false" href="#production">Наша
            продукцiя</a></div>
    </div>
</div>
