<div class="section-wrapper" id="production">
  <h2>Наша продукція</h2>
  <div class="production-wrapper">
    <div class="production-item">
      <ng-container *ngFor="let product of mainProducts">
        <a class="production-link" [ngStyle]="{'background-image': product.icon}">
          <span> {{product.name}} </span>
          <app-count-buttons class="buttons" [linkToGallery]="product.link_to_gallery"></app-count-buttons>
        </a>
      </ng-container>

    </div>
  </div>
</div>
<div class="production-other">
  <div class="other-wrapper">
    Інші наші пропозиції</div>
</div>
<div class="section-wrapper">
  <div class="production-wrapper">
    <div class="production-item">
      <ng-container *ngFor="let product of otherProducts">
        <a class="production-link" [ngStyle]="{'background-image': product.icon}">
          <span> {{product.name}} </span>
          <app-count-buttons class="buttons" [linkToGallery]="product.link_to_gallery"></app-count-buttons>
        </a>
      </ng-container>
    </div>
  </div>
</div>

