<div class="section-wrapper">
  <h2>Наші роботи</h2>
  <div class="works_wrapper">
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
    >
      <div *ngFor="let image of images;trackBy: trackByFn" class="slider-item" ngxSlickItem>
        <a [href]="image.big_src" data-fancybox="gallery"> <img [alt]="image.type" [attr.data-lazy]="image.src"
                                                                width="100%"/></a>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
