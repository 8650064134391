<app-header></app-header>
<app-up-button></app-up-button>
<app-head-slider></app-head-slider>
<app-our-mission></app-our-mission>
<app-our-prod></app-our-prod>
<app-about-us></app-about-us>
<app-our-feedback></app-our-feedback>
<app-our-partners></app-our-partners>
<app-our-works></app-our-works>
<app-form-feedback></app-form-feedback>
<app-footer></app-footer>

